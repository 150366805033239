import React from 'react'
import { Box, Button, Grid } from '@mui/material'
import { UseFormReturn } from 'react-hook-form';
import { CorporateRequest } from '../api/request.types';
import FormInputs, { inputView } from './formInputs.component';

interface EditCorporateProps {
    useFormParams:UseFormReturn<CorporateRequest>
    onClose:() => void
    onSubmit:(data:CorporateRequest) => Promise<void>
}
 
enum CorporatesFieldsRoute {
    featureImage = 'featureImage',
    name = 'corporateName',
    description = 'description'
}

export default function EditCorporate(props:EditCorporateProps) {
        const {onClose,onSubmit,useFormParams} = props;


    const CorporateFields:inputView[] = [
        {fieldName: CorporatesFieldsRoute.name, labelText: 'שם', colspan: 12},
        {fieldName: CorporatesFieldsRoute.featureImage, labelText: 'תמונה', colspan: 12, type:'file'},
        {fieldName: CorporatesFieldsRoute.description, labelText: 'תיאור', colspan: 12,type:'textarea'},

      ];
  
    return (

    <form onSubmit={useFormParams.handleSubmit(async (data) => {await onSubmit(data); onClose()})}>
        <Grid container spacing={1}>
            <FormInputs fields={CorporateFields} useFormParams={useFormParams} />
        </Grid>
        <Box display={'flex'}>
            <Button type='submit'>עדכן</Button>
            <Button onClick={onClose}>ביטול</Button>
        </Box>
    </form>





    )
}