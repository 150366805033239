import { Box, Button, Grid } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { FlattenedStrapiObj, PublicationTypes } from '../api/models.types';
import { OrderRequest } from '../api/request.types';
import { CorporateResponse } from '../api/response.types';
import FormInputs, { inputView } from './formInputs.component';

enum OrderFieldRoute {
  customerMail= "customerMail",
  firstName="firstName",
  lastName="lastName",
  phone="phone",
  status="status",
  invoiceUrl="invoiceUrl",
  documentId="documentId",
  stage="stage",
  product="product",
  paymentProvider="paymentProvider",
}

enum CondolenceFieldsRoutes {
    intro = 'publicationDetails.intro',
    personTitle = 'publicationDetails.personTitle',
    relationToDead = 'publicationDetails.relationToDead',
    deadName = 'publicationDetails.deadName',
    consoledName = 'publicationDetails.consoledName',
    consolers = 'publicationDetails.consolers',
    consoleSentence = 'publicationDetails.consoleSentence',
    companyLogoUrl = 'publicationDetails.companyLogoUrl',
    content = 'publicationDetails.content',
    companyName = 'publicationDetails.companyName',
    publisherType = 'publicationDetails.publisherType',
    deadGender = 'publicationDetails.deadGender',
    deadImageUrl = 'publicationDetails.deadImageUrl',
    sendToN12 = 'publicationDetails.sendToN12'
  }


  enum ObituaryFieldsRoute {
    deadName = 'publicationDetails.deadName',
    deadGender = 'publicationDetails.deadGender',
    intro = 'publicationDetails.intro',
    title = 'publicationDetails.title',
    relationToDead = 'publicationDetails.relationToDead',
    shivahLocation = 'publicationDetails.shivahLocation',
    funeralLocation = 'publicationDetails.funeralLocation',
    funeralDate = 'publicationDetails.funeralDate',
    shivahStartsHour = 'publicationDetails.shivahStartsHour',
    shivahEndsHour = 'publicationDetails.shivahEndsHour',
    shivah2ndStartsHour = 'publicationDetails.shivah2ndStartsHour',
    shivah2ndEndsHour = 'publicationDetails.shivah2ndEndsHour',
    content = 'publicationDetails.content',
    imageUrl = 'publicationDetails.imageUrl',
    signature = 'publicationDetails.signature',
    sendToN12 = 'publicationDetails.sendToN12'
}

enum RemembranceFieldsRoute {
  deadName = 'publicationDetails.deadName',
  deadGender = 'publicationDetails.deadGender',
  intro = 'publicationDetails.intro',
  title = 'publicationDetails.title',
  relationToDead = 'publicationDetails.relationToDead',
  ceremonyLocation = 'publicationDetails.ceremonyLocation',
  graveAscentionLocation = 'publicationDetails.graveAscentionLocation',
  graveAscentionDate = 'publicationDetails.graveAscentionDate',
  ceremonyDate = 'publicationDetails.ceremonyDate',
  content = 'publicationDetails.content',
  imageUrl = 'publicationDetails.imageUrl',
  signature = 'publicationDetails.signature',
  sendToN12 = 'publicationDetails.sendToN12'
}

enum corporateFieldsRoute {
  corporate = 'publicationDetails.corporate'
}

interface EditPublicationProps {
    useFormParams:UseFormReturn<any>
    onClose:() => void
    onSubmit:(data:OrderRequest) => Promise<void>
    publicationType: PublicationTypes
    corporates:FlattenedStrapiObj<CorporateResponse>[]
}

export default function EditOrder(props:EditPublicationProps) {
const {useFormParams,onClose, onSubmit, publicationType,corporates} = props;


  const orderFields:inputView[] = [
    {fieldName: OrderFieldRoute.firstName,colspan:6, labelText:"שם פרטי"},
    {fieldName: OrderFieldRoute.lastName,colspan:6, labelText:"שם משפחה"},
    {fieldName: OrderFieldRoute.customerMail,colspan:6, labelText:"מייל"},
    {fieldName: OrderFieldRoute.phone,colspan:6, labelText:"טלפון"},
    {fieldName: OrderFieldRoute.invoiceUrl,colspan:6, labelText:"קישור קבלה"},
    {fieldName: OrderFieldRoute.documentId,colspan:6, labelText:"מספר קבלה"},
  ]

  const obituaryFields:inputView[] = [
    {fieldName: ObituaryFieldsRoute.deadGender, labelText: 'מגדר', colspan: 12, type: 'radio', values: [{display: 'זכר', value: 'male'}, {display: 'נקבה', value: 'female'}]},
    {fieldName: ObituaryFieldsRoute.intro, labelText: 'שורת פתיחה', colspan: 6},
    {fieldName: ObituaryFieldsRoute.relationToDead, labelText: 'קרבה לנפטר', colspan: 5},
    {fieldName: ObituaryFieldsRoute.title, labelText: 'תואר', colspan: 6},
    {fieldName: ObituaryFieldsRoute.deadName, labelText: 'שם נפטר', colspan: 6, },
    {fieldName: ObituaryFieldsRoute.funeralDate, labelText: 'תאריך הלוויה', colspan: 6,  type: 'datetime-local', registerOptions: {required: false}, otherOpts: {displayHebrewDate: true}},
    {fieldName: ObituaryFieldsRoute.funeralLocation, labelText: 'מיקום הלוויה', colspan: 6, registerOptions: {required: false}},
    {fieldName: ObituaryFieldsRoute.shivahLocation, labelText: 'מיקום שבעה', colspan: 12, registerOptions: {required: false}},
    {fieldName: ObituaryFieldsRoute.shivahStartsHour, labelText: 'תחילת שעת ניחום ראשונה', colspan: 3,type:'time', registerOptions: {required: false}},
    {fieldName: ObituaryFieldsRoute.shivahEndsHour, labelText: 'סוף שעת ניחום ראשונה', colspan: 3, type:'time', registerOptions: {required: false}},
    {fieldName: ObituaryFieldsRoute.shivah2ndStartsHour, labelText: 'תחילה שעת ניחום שניה', colspan: 3,type:'time', registerOptions: {required: false}},
    {fieldName: ObituaryFieldsRoute.shivah2ndEndsHour, labelText: 'סוף שעת ניחום שניה', colspan: 3, type:'time', registerOptions: {required: false}},
    {fieldName: ObituaryFieldsRoute.signature, labelText: 'שורת חתימה', colspan: 6},
    {fieldName: ObituaryFieldsRoute.imageUrl, labelText: 'תמונת נפטר', colspan: 6, type: 'file', registerOptions: {required: false}},
    {fieldName: ObituaryFieldsRoute.sendToN12,colspan: 12, labelText: 'לפרסם ב-n12', type: 'radio', values: [{value: 'yes', display: 'פרסם'}, {value: 'no', display: 'לא לפרסם'}]},
    {fieldName: ObituaryFieldsRoute.content, labelText: 'תוכן', colspan: 12, type: 'textarea', registerOptions: {required: false}},
];


  const condolenceFields:inputView[] = [
    {fieldName: CondolenceFieldsRoutes.deadGender, labelText: 'מגדר', colspan: 12, type: 'radio', values: [{display: 'זכר', value: 'male'}, {display: 'נקבה', value: 'female'}]},
    {fieldName: CondolenceFieldsRoutes.intro, colspan: 4, labelText: 'שורת פתיחה'},
    {fieldName: CondolenceFieldsRoutes.consoledName, colspan: 4, labelText: 'שם מנוחם' },
    {fieldName: CondolenceFieldsRoutes.personTitle, colspan: 4, labelText: 'תואר נפטר'},
    {fieldName: CondolenceFieldsRoutes.relationToDead,colspan: 4, labelText: 'קרבה לנפטר'},
    {fieldName: CondolenceFieldsRoutes.deadName,colspan: 4, labelText: 'שם נפטר'},
    {fieldName: CondolenceFieldsRoutes.deadImageUrl,colspan: 6, labelText: 'תמונת נפטר', registerOptions: {required: false}, type: 'file'},
    {fieldName: CondolenceFieldsRoutes.consoleSentence, colspan: 12, labelText: 'משפט ניחומים', registerOptions: {required: false}},
    {fieldName: CondolenceFieldsRoutes.publisherType,colspan: 12, labelText: 'סוג מפרסם', type: 'radio', values: [{value: 'private', display: 'פרטי'}, {value: 'commercial', display: 'עסקי'}], otherOpts: {onContact: () => { useFormParams.setValue(CondolenceFieldsRoutes.companyName, ''); useFormParams.setValue(CondolenceFieldsRoutes.companyLogoUrl, ''); }}},
    {fieldName: CondolenceFieldsRoutes.companyLogoUrl,colspan: 6,labelText:"לוגו חברה", hide: useFormParams.watch(CondolenceFieldsRoutes.publisherType) !== 'commercial', registerOptions: {required: false}, type: 'file'},
    {fieldName: CondolenceFieldsRoutes.companyName, colspan: 6,labelText:"שם חברה", hide: useFormParams.watch(CondolenceFieldsRoutes.publisherType) !== 'commercial', registerOptions: {required: false}},
    {fieldName: CondolenceFieldsRoutes.consolers, colspan: 3, hide: useFormParams.watch(CondolenceFieldsRoutes.publisherType) !== 'private'},
    {fieldName: CondolenceFieldsRoutes.sendToN12,colspan: 12, labelText: 'לפרסם ב-n12', type: 'radio', values: [{value: 'yes', display: 'פרסם'}, {value: 'no', display: 'לא לפרסם'}]},
    {fieldName: CondolenceFieldsRoutes.content,colspan: 12, labelText: 'תוכן', type: 'textarea'},
  ];
 
  const remembranceFields:inputView[] = [
    {fieldName: RemembranceFieldsRoute.deadGender, labelText: 'מגדר', colspan: 12, type: 'radio', values: [{display: 'זכר', value: 'male'}, {display: 'נקבה', value: 'female'}]},
    {fieldName: RemembranceFieldsRoute.intro, labelText: 'שורת פתיחה', colspan: 6},
    {fieldName: RemembranceFieldsRoute.relationToDead, labelText: 'קרבה לנפטר', colspan: 5},
    {fieldName: RemembranceFieldsRoute.title, labelText: 'תואר', colspan: 6},
    {fieldName: RemembranceFieldsRoute.deadName, labelText: 'שם נפטר', colspan: 6, },
    {fieldName: RemembranceFieldsRoute.ceremonyDate, labelText: 'תאריך האזכרה', colspan: 6,  type: 'datetime-local', registerOptions: {required: false}, otherOpts: {displayHebrewDate: true}},
    {fieldName: RemembranceFieldsRoute.ceremonyLocation, labelText: 'מיקום האזכרה', colspan: 6, registerOptions: {required: false}},
    {fieldName: RemembranceFieldsRoute.graveAscentionLocation, labelText: 'מיקום עלייה לקבר', colspan: 6, registerOptions: {required: false}},
    {fieldName: RemembranceFieldsRoute.graveAscentionDate, labelText: 'תאריך עלייה לקבר', colspan: 6,  type: 'datetime-local', registerOptions: {required: false}, otherOpts: {displayHebrewDate: true}},
    {fieldName: RemembranceFieldsRoute.signature, labelText: 'שורת חתימה', colspan: 6},
    {fieldName: RemembranceFieldsRoute.imageUrl, labelText: 'תמונת נפטר', colspan: 6, type: 'file', registerOptions: {required: false}},
    {fieldName: RemembranceFieldsRoute.sendToN12,colspan: 12, labelText: 'לפרסם ב-n12', type: 'radio', values: [{value: 'yes', display: 'פרסם'}, {value: 'no', display: 'לא לפרסם'}]},
    {fieldName: RemembranceFieldsRoute.content, labelText: 'תוכן', colspan: 12, type: 'textarea', registerOptions: {required: false}},
  ];

  const corporateField:inputView = {
    fieldName:corporateFieldsRoute.corporate,labelText:'חברה',type:'select',colspan:6, values:corporates?.map(x => ({display:x.corporateName,value:x.id}))
  }

  const fieldsMapper:{[key in PublicationTypes]: any} = {
    condolence:condolenceFields,
    obituary:obituaryFields,
    remembrance:remembranceFields
  }

  if (!publicationType) {
    return null
  }

  return (
    <form onSubmit={useFormParams.handleSubmit(async (data:OrderRequest) => {
      data.publicationDetails.corporate = data.publicationDetails.corporate || null;
      await onSubmit(data); onClose()
      
      })}>
        <Grid container spacing={1}>
            <FormInputs fields={[...orderFields, ...(fieldsMapper[publicationType]),corporateField]} useFormParams={useFormParams} />
        </Grid>
        <Box display={'flex'}>
            <Button type='submit'>עדכן</Button>
            <Button onClick={onClose}>ביטול</Button>
        </Box>
    </form>

  )
}