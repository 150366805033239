import { request } from "../utils/api.util";
import { StrapiObj } from "./models.types";
import { CouponRequest } from "./request.types";
import { CouponResponse } from "./response.types";

export default class CouponsAPI {
    static async getAll() {
        return await request<StrapiObj<CouponResponse>[]>({
            uri: 'coupons',
            method: "GET",
        });
    }

    static async getById(id: number) {
        return await request<StrapiObj<CouponResponse>>({
            uri: `coupons/${id}`,
            method: "GET",
        });
    }

    static async create(data: CouponRequest) {
        return await request<StrapiObj<CouponResponse>>({
            uri: 'coupons',
            method: "POST",
            body: {
                data
            }
        });
    }

    static async delete(id: number) {
        return await request<StrapiObj<CouponResponse>>({
            uri: `coupons/${id}`,
            method: "DELETE",
        });
    }

    static async update(id: number, data: CouponRequest) {
        return await request<StrapiObj<CouponResponse>>({
            uri: `coupons/${id}`,
            method: "PUT",
            body: {
                data
            }
        });
    }
}