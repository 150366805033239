import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Input, ListItemText, MenuItem, Modal, Radio, RadioGroup, Select, Stack, TextareaAutosize, Typography } from '@mui/material';
import { log } from 'console';
import React, { HTMLInputTypeAttribute, useState } from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import { uploadFileLocallyWithBucketeer } from '../utils/bucketeer.util';


export interface inputView {
    fieldName: string
    labelText?: string,
    colspan?:number
    type?: HTMLInputTypeAttribute | 'textarea' | 'auto-complete',
    hide?:boolean
    values ?: {value:string | number, display?:string | JSX.Element}[]
    registerOptions?: RegisterOptions | false
    otherOpts?: {
        hideFirstOpt?: boolean,
        direction?: 'column' | 'row',
        additionalStyle?: React.CSSProperties,
        onContact?: (value?:any) => any,
        displayHebrewDate?:boolean,
        multipleSelect?:boolean
    }
}


interface FormInputsProps {
    fields:inputView[]
    useFormParams: UseFormReturn<any>
}

export default function FormInputs(props:FormInputsProps) {
    const {fields, useFormParams} = props;
    const { register, control, formState: {errors}, setValue, watch } = useFormParams;
    const [imgDisplayUrl, setImgDisplayUrl] = useState<string | undefined>(undefined)

    const defaultValidation = {};

    const handleFileUpload = async (e:React.ChangeEvent<HTMLInputElement>, fieldName:string) => {
        const files = e.target.files;
        if (!files) {
            throw Error('no file found');
        }
        try {
            setValue(`${fieldName}Name`, files?.[0].name);
            const fileUrl = await uploadFileLocallyWithBucketeer(files);
            console.log(fileUrl)
            setValue(fieldName, fileUrl);
        } catch (err) {
            console.log(err);
            
        }
    };


    const renderInput = (params:inputView, index:number) => {
        if (params.hide) {
            return null;
        }


        let isRendered = false;
        const rendered = () => {
            isRendered = true;
            return true;
        };


        const registerForm = (onContact?:any) => params.registerOptions === false ? null : register(params.fieldName, {...defaultValidation, ...params.registerOptions, onChange: () => onContact && onContact(watch(params.fieldName))});

        return (
            <Grid key={index} item xs={params.colspan}>
                <FormControl fullWidth>

                    {(params.labelText || params.labelText === '') && <FormLabel style={{visibility: params.labelText === '' ? 'hidden' : 'visible'}}>{params.labelText || 'hidden'}</FormLabel>}

                    {params.type === 'textarea' && rendered() && <TextareaAutosize {...registerForm()}/>}

                    {params.type === 'radio' && rendered() &&
                        <Controller
                            name={params.fieldName}
                            control={control}
                            render={({ field }) => {
                                return (
                                <RadioGroup {...field} row={(params.otherOpts?.direction ?? 'row') === 'row'} onChange={(e) => { field.onChange(e); params.otherOpts?.onContact && params.otherOpts?.onContact(); }} value={field.value}>
                                        {params.values?.map((radioBTN, i) => (
                                            <FormControlLabel key={i} value={radioBTN.value} control={<Radio />} label={radioBTN.display} />
                                        ))}
                                </RadioGroup>)}}
                        />
                    }

                    {params.type === 'select' && rendered() &&
                        <Controller
                        name={params.fieldName}
                        control={control}
                        render={({ field }) => {
                            return (
                            <Select {...field}
                                onChange={(e) => { field.onChange(e); params.otherOpts?.onContact && params.otherOpts?.onContact(); }}
                                multiple={params?.otherOpts?.multipleSelect}
                                value={field.value}
                                renderValue={params?.otherOpts?.multipleSelect ? (selected) => {
                                    return selected?.map((selectOpt, i) => (
                                        <Chip key={i} label={params.values.find(x => x.value === selectOpt)?.display} />
                                    ))
                                } : null}
                            >
                                    {!params?.otherOpts?.multipleSelect && (
                                    <MenuItem value={0}>
                                        <ListItemText primary={'-'} />
                                    </MenuItem>)}
                                {params.values?.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <ListItemText primary={option.display} />
                                    </MenuItem>
                                ))}
                            </Select>)}}
                        />
                    }

                    {params.type === 'file' && rendered() &&
                        <FormControl>
                            <Button
                                variant="contained"
                                component="label">
                                    {watch(params.fieldName) ? 'החלף תמונה' : 'בחר תמונה'}
                                <input hidden accept={'image/jpg, image/jpeg, image/png'} type={'file'} onChange={(e) => handleFileUpload(e, params.fieldName )} />
                            </Button>
                            <Button onClick={() => setImgDisplayUrl(watch(params.fieldName))}>הצג תמונה</Button>
                        </FormControl>
                    }
                    {params.type === 'checkbox' && rendered() &&
                        <FormControl>
                        <FormGroup>
                            {params.values.map((item,i) => (
                                <FormControlLabel
                                    label={item.value}
                                    key={i}
                                    control={
                                        <Controller
                                        name={item.value.toString()}
                                        control={control}
                                        render={({field}) => (
                                            <Checkbox {...field}/>
                                        )}
                                        />
                                    }                                        
                                />
                            ))}
                        </FormGroup>
                        </FormControl>
                    }

                    {
                        !isRendered && <Input type={params.type || 'text'} fullWidth {...registerForm()}/>
                    }

                </FormControl>
            </Grid>
        );
    };


    return (
        <>
            {fields.map((field, i) => {
                return renderInput(field, i);
            })}
            <Modal
                open={!!imgDisplayUrl}
                onClose={() => setImgDisplayUrl(undefined)}>
                <Box sx={{backgroundColor:'white', position: 'absolute',top:'10%',left:'10%'}}>
                    <img width={500} src={imgDisplayUrl} alt='' />
                </Box>
            </Modal>
    </>
    );
}
