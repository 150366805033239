import { request } from "../utils/api.util";
import { UserResponse } from "./response.types";


export default class UserAPI {

    static async getUser (id:number) {
        return await request<UserResponse>({
            uri:`users/${id}`,
            method:"GET",
        })
    }
}