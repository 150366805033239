import React from 'react'
import useSWR from 'swr';
import MemorialsAPI from '../api/memorials.api';
import { MemorialStatus, PublicationTypes, StrapiObj } from '../api/models.types';
import { statusColors } from './useOrders.hook';
import {BASE_URL} from '../utils/api.util'
import { DeeplyNestedMemorial } from '../api/response.types';
import { publicationTypeDisplayMapper } from '../utils/displayTaskType.util';

export const statusNames:{[k in MemorialStatus] : string} = {
  error:'תקלה',
  approved:'אושר',
  pending:'ממתין',
  rejected:'סורב'
}

export default function useMemorials() {

    const {data,error,mutate} = useSWR('getAllMemorials', MemorialsAPI.getAllMemorials);

    const getDeeplyNestedMemorialPublicationType = (memorial:StrapiObj<DeeplyNestedMemorial>) => {
      return !!memorial.attributes?.condolence?.data?.id ? PublicationTypes.Condolence :
         !!memorial.attributes?.obituary?.data?.id ? PublicationTypes.Obituary :
         !!memorial.attributes?.remembrance?.data?.id ? PublicationTypes.Remembrance :
          null
    }



    const flattenedData = data?.data?.map(memorial => {
      const publicationType = getDeeplyNestedMemorialPublicationType(memorial)
      const publication_id = memorial.attributes?.[publicationType]?.data?.id;

       return {...memorial.attributes,
        status_color: statusColors[memorial.attributes?.status || MemorialStatus.error],
        id:memorial.id,
        display_publication_type: publicationTypeDisplayMapper[publicationType],
        display_status: statusNames[memorial.attributes?.status || MemorialStatus.error],
        publication_type:publicationType,
        publication_id,
        publication_url: `${BASE_URL}/publication/?publicationType=${publicationType}&id=${publication_id}`,
      }
    });
  const setMemorialStatus = async (id:number, status: MemorialStatus) => {
      await MemorialsAPI.setMemorialStatus(id,status);
      mutate()
  }

  return {
    memorials:flattenedData,
    setMemorialStatus,
  }
}