import axios, { AxiosRequestHeaders, Method } from 'axios';
import Cookies from 'universal-cookie';

export type RequestParams = {
    uri: string;
    method: Method;
    body?: any;
    params?: any;
    removeEndSlash?: boolean;
    authentication?: boolean;
    pagination?:boolean;
    fullRouteProvided?:boolean
};

export const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.adolam.co.il' : 'https://9f9b-192-116-200-178.ngrok-free.app';
export interface RequestResponseType<T> {
    status: number;
    data: T;
}
console.log(process.env.NODE_ENV);
const cookies = new Cookies();

export async function request<T>(params: RequestParams) {
    const jwt = cookies.get('AdminAdolamJWT');

        const response = await axios.request({
            method: params.method,
            data: params.body,
            params: params.params,
            url: !params.fullRouteProvided ? `${BASE_URL}/api/${params.uri}` : params.uri,
            withCredentials: true,
            headers: {
                Authorization: jwt ? `Bearer ${cookies.get('AdminAdolamJWT')}` : null,
            },
        });
        
        const data = response.data.data || response.data;
        const responseObj: RequestResponseType<T> = { status: response.status, data: data as T};
        return responseObj;
}