import { useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import { appRegions } from '../api/models.types';
import { SupplierRequest } from '../api/request.types';
import { SupplierResponse } from '../api/response.types';
import SupplierAPI from '../api/supplier.api';
import SupplierCategoryAPI from '../api/supplierCategory.api';
import { flatData } from '../utils/flatData.util';

export default function UseSuppliers() {

    const {data, error, mutate} = useSWR('getAllSuppliers', SupplierAPI.getAll);

    const suppliersUseFormParams = useForm<SupplierRequest>();
    const [updateSupplierId, setUpdateSupplierId] = useState<number>(null)

    const flattenedData = flatData<SupplierResponse>(data?.data)

    const addSupplier = async (data:SupplierRequest) => {
        await SupplierAPI.create(data);
        mutate();
    }
   
    const deleteSupplier = async (id:number) => {
        await SupplierAPI.delete(id);
        mutate();
    }

    const openUpdateSupplierModal = async (id:number) => {
        const supplierData = await SupplierAPI.getOne(id);
        const supplierCategories = await SupplierCategoryAPI.getSupplierCategories(id);
        const supplierRequest = {...supplierData?.data?.attributes, supplier_categories:supplierCategories?.data?.map(x => x.id.toString())}

        setUpdateSupplierId(id);
        suppliersUseFormParams.reset(supplierRequest)
    }

    const openAddSupplierModal = async () => {
        setUpdateSupplierId(-1);
        suppliersUseFormParams.reset({supplier_categories:[], region: appRegions.all})
    }
   
    const updateSupplier = async (id:number, data:SupplierRequest) => {
        await SupplierAPI.update(id,data);
        mutate();
    }
    

  return {
    suppliers:flattenedData,
    addSupplier,
    deleteSupplier,
    updateSupplier,
    suppliersUseFormParams,
    openUpdateSupplierModal,
    updateSupplierId,
    openAddSupplierModal,
    closeSupplierModal: () => {setUpdateSupplierId(null);suppliersUseFormParams.reset({})}
  }
}
