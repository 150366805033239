import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { UseFormReturn } from 'react-hook-form';
import { SupplierCategoryRequest } from '../api/request.types';
import FormInputs, { inputView } from './formInputs.component';

interface EditSupplierCategoryProps {
    useFormParams:UseFormReturn<SupplierCategoryRequest>
    onClose:() => void
    onSubmit:(data:SupplierCategoryRequest) => Promise<void>
}
 
enum SupplierCategoryFieldsRoute {
    name = 'name',
    image = 'image',
    description = 'description'
    
}

export default function EditSupplierCategory(props:EditSupplierCategoryProps) {
        const {onClose,onSubmit,useFormParams} = props;

  
  

    const categoryFields:inputView[] = [
        {fieldName: SupplierCategoryFieldsRoute.name, labelText: 'שם', colspan: 12},
        {fieldName: SupplierCategoryFieldsRoute.image, labelText: 'תמונה', colspan: 12,type:'file'},
        {fieldName: SupplierCategoryFieldsRoute.description, labelText: 'תיאור', colspan: 12,type:'textarea'},
      ];
  
    return (

        <form onSubmit={useFormParams.handleSubmit(async (data) => {await onSubmit(data); onClose()})}>
        <Grid container spacing={1}>
            <FormInputs fields={categoryFields} useFormParams={useFormParams} />
        </Grid>
        <Box display={'flex'}>
            <Button type='submit'>עדכן</Button>
            <Button onClick={onClose}>ביטול</Button>
        </Box>
    </form>





    )
}