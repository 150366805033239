import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { UseFormReturn } from 'react-hook-form';
import { appRegions, StrapiObj } from '../api/models.types';
import { SupplierRequest } from '../api/request.types';
import { SupplierCategoryResponse } from '../api/response.types';
import FormInputs, { inputView } from './formInputs.component';

interface EditSupplierProps {
    useFormParams:UseFormReturn<SupplierRequest>
    onClose:() => void
    onSubmit:(data:SupplierRequest) => Promise<void>
    categories: StrapiObj<SupplierCategoryResponse>[]
}
 
enum SuppliersFieldsRoute {
    featureImage = 'featureImage',
    city = 'city',
    address = 'address',
    email = 'email',
    facebookLink = 'facebookLink',
    name = 'name',
    phone = 'phone',
    priority = 'priority',
    region = 'region',
    websiteUrl = 'websiteUrl',
    zipCode = 'zipCode',
    supplier_categories = 'supplier_categories',
    description = 'description'
}

export default function EditSupplier(props:EditSupplierProps) {
        const {onClose,onSubmit,useFormParams, categories} = props;

        const regionsMapper = {
            'ALL':'כל הארץ',
            'JERUSALEM':'ירושלים',
            'NORTH':'צפון',
            'HAIFA':'חיפה',
            'CENTER':'מרכז',
            'SOUTH':'דרום',
            'TEL_AVIV':'תל אביב',
            'JUDEA_AND_SAMARIA':'יהודה ושומרון',
        }

        const categoriesValues = categories?.map(x => ({value:x.id.toString(), display:x.attributes.name }));
        const regionsValues = Object.values(appRegions).map(x => ({value:x, display:regionsMapper[x]}))

    const supplierFields:inputView[] = [
        {fieldName: SuppliersFieldsRoute.name, labelText: 'שם', colspan: 12},
        {fieldName: SuppliersFieldsRoute.email, labelText: 'אימייל', colspan: 6},
        {fieldName: SuppliersFieldsRoute.phone, labelText: 'טלפון', colspan: 6},
        {fieldName: SuppliersFieldsRoute.region, labelText: 'אזור', colspan: 6, type:'select', values:regionsValues },
        {fieldName: SuppliersFieldsRoute.city, labelText: 'עיר', colspan: 6},
        {fieldName: SuppliersFieldsRoute.address, labelText: ' כתובת', colspan: 6},
        {fieldName: SuppliersFieldsRoute.zipCode, labelText: 'מיקוד', colspan: 6},
        {fieldName: SuppliersFieldsRoute.featureImage, labelText: 'תמונה', colspan: 6, type:'file'},
        {fieldName: SuppliersFieldsRoute.websiteUrl, labelText: 'אתר', colspan: 6},
        {fieldName: SuppliersFieldsRoute.facebookLink, labelText: 'פייסבוק', colspan: 6},
        {fieldName: SuppliersFieldsRoute.supplier_categories, labelText: 'קטגוריות', colspan: 6, type:'select', values: categoriesValues, otherOpts:{multipleSelect:true}},
        {fieldName: SuppliersFieldsRoute.priority, labelText: 'עדיפות', colspan: 6},
        {fieldName: SuppliersFieldsRoute.description, labelText: 'תיאור', colspan: 6,type:'textarea'},

      ];
  
    return (

    <form onSubmit={useFormParams.handleSubmit(async (data) => {await onSubmit(data); onClose()})}>
        <Grid container spacing={1}>
            <FormInputs fields={supplierFields} useFormParams={useFormParams} />
        </Grid>
        <Box display={'flex'}>
            <Button type='submit'>עדכן</Button>
            <Button onClick={onClose}>ביטול</Button>
        </Box>
    </form>





    )
}