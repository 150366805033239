import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { publicaionImagesConst, PublicationImagesSelection, PublicationImagesType, PublicationTypes } from '../api/models.types';
import OrdersAPI from '../api/orders.api';
import { inputView } from '../components/formInputs.component';

export enum GenenerateDialogsNames {
    regenAllOrdersImages = 'רענון כל התמונות - פעולה רגישה',
    regenSingleOrderImages = 'רענון תמונה בודדת'
}

export default function UseGenerateOrderImages() {
    const [genDialog, setGenDialog] = useState<{dialogName:GenenerateDialogsNames,orderId?: number, publicationType?:PublicationTypes}>(null);
    const genImagesUseFormParams = useForm<PublicationImagesSelection>();

    const openDialog = (dialogName:GenenerateDialogsNames, orderId?:number,publicationType?:PublicationTypes) => {
        setGenDialog({dialogName,orderId,publicationType});
    }

    const closeDialog = () => {
        setGenDialog(null);
        genImagesUseFormParams.reset();
    }
    
    const regenerateOutputImages = async (data: PublicationImagesSelection) => {
        const formattedImagesTypes = Object.keys(data)?.filter(x => data[x]).map(x => x) as PublicationImagesType[]
             if (genDialog.dialogName === GenenerateDialogsNames.regenAllOrdersImages) {
                closeDialog()
                await OrdersAPI.regenerateAllImages(formattedImagesTypes)
            return;
        }

        if (genDialog.dialogName === GenenerateDialogsNames.regenSingleOrderImages) {
                closeDialog()
                await OrdersAPI.regenerateImages(genDialog.orderId,genDialog.publicationType,formattedImagesTypes)
            return
        }
        alert('error sending request')
    }

    const formFields:inputView = {fieldName:'ImagesTypes',labelText:'בחר סוג תמונה לרענון', type:'checkbox',values:publicaionImagesConst.map(x => ({value:x, display:x})),otherOpts:{direction:'column'},registerOptions:{required:true}}

    return {
        genDialog,
        openDialog,
        closeDialog,
        regenerateOutputImages,
        genImageFormFields:formFields,
        genImagesUseFormParams,
    }
}