import { request } from "../utils/api.util";
import { MemorialStatus, StrapiObj } from "./models.types";
import { DeeplyNestedMemorial } from "./response.types";

export default class MemorialsAPI {

    static async getAllMemorials() {
        return await request<StrapiObj<DeeplyNestedMemorial>[]>({
            uri:"memorials?populate=deep,3",
            method:"GET"
        })
    }

    static async setMemorialStatus(id:number,status:MemorialStatus) {
        return await request<void>({
            uri:`memorials/${id}`,
            method:"PUT",
            body:{
                data:{status}
            }
        })
    }       
}