import axios from 'axios';
import { request } from '../utils/api.util';

interface SignedUrlResponse {
    fileKey:string
    filename:string
    publicUrl:string
    signedUrl:string
}

export default class BucketeerAPI {

    static async getSignedUrl(fileType:string) {
        return await request<SignedUrlResponse>({
            uri: 'getSignedUrl',
            method: 'GET',
            params: {fileType}
        });
    }

    static async upload(uri:string, obj:any) {
        await axios.put(uri, obj);
    }


}