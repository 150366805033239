import {useCallback, useMemo} from 'react'
import OrdersAPI from '../api/orders.api'
import useSWR from 'swr';
import { OrderStatus, PublicationsIdsDictionary, PublicationTypes, StrapiObj } from '../api/models.types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObituaryRequest, OrderRequest, PublicationRequest, RemembranceRequest } from '../api/request.types';
import dayjs from 'dayjs';
import { DeeplyNestedOrder } from '../api/response.types';
import { publicationTypeDisplayMapper } from '../utils/displayTaskType.util';
import { resetTimeZone } from './datePrser.util';

export const statusNames:{[k in OrderStatus] : string} = {
  complete:'ממתין לאישור',
  error:'תקלה',
  approved:'אושר',
  pending:'לא הושלם',
  rejected:'סורב'
}

export const statusColors:{[k in OrderStatus] : string} = {
  complete:'blue',
  error:'red',
  approved:'green',
  pending:'darkorange',
  rejected:'purple'
}

export default function UseOrders() {
    const [order, setOrder] = useState<OrderRequest | null>(null);
    const [statusFilter, setStatusFilter] = useState(['ממתין לאישור'])
    const useFormParams = useForm()

    const formatDate = (date:string) => {
        return dayjs(date)?.format(('YYYY-MM-DDTHH:mm'));
    }

    const formatOrder = useCallback((order:OrderRequest) => {
      const publicationType = PublicationsIdsDictionary[order?.product];
      let funeralDate, ceremonyDate, graveAscentionDate;
      if (publicationType === PublicationTypes.Obituary) {
          funeralDate = formatDate((order.publicationDetails as ObituaryRequest).funeralDate);
      }
      
      if (publicationType === PublicationTypes.Remembrance) {
          ceremonyDate = formatDate((order.publicationDetails as RemembranceRequest).ceremonyDate);
          graveAscentionDate = formatDate((order.publicationDetails as RemembranceRequest).graveAscentionDate);
      }
      return {...order, publicationDetails: {...order.publicationDetails, ...(!!funeralDate ? {funeralDate} : null) ,...(ceremonyDate ? {ceremonyDate}: null),...(graveAscentionDate ? {graveAscentionDate}: null)}}
  },[])

  useEffect(() => {
    if (order) {
      useFormParams.reset(formatOrder(order));
    }
  },  [formatOrder, order, useFormParams])

    const {data,error,mutate} = useSWR('getAllOrders', OrdersAPI.getAllOrders);

    const flattenedData = data?.data?.map(order => {
      const publicationType = PublicationsIdsDictionary[order.attributes?.product?.data?.id];
      return {
         ...order.attributes,
         display_status: statusNames[order.attributes.status || OrderStatus.error],
         status_color: statusColors[order.attributes.status || OrderStatus.error],
         publicationType,
         display_publication_type: publicationTypeDisplayMapper[publicationType],
         id:order?.id,
         publicationId: order.attributes?.[publicationType]?.data?.id,
         email:order.attributes.customerMail,
         payment_time_stamp: dayjs(order?.attributes?.paymentTimeStamp ?? 0).toDate(),
         display_payment_time_stamp: order.attributes.paymentTimeStamp ? dayjs(order.attributes.paymentTimeStamp).format('DD/MM/YYYY HH:mm') : '',
         dead_name: order.attributes?.[publicationType]?.data?.attributes?.deadName
        }
    });
    const filteredData = useMemo(() => flattenedData?.filter(order => statusFilter.includes(order?.display_status)), [flattenedData, statusFilter])

    const setOrderStatus = async (id:number,status:OrderStatus) => {
        await OrdersAPI.setOrderStatus(id,status);
        mutate()
    }

    const getDeeplyNestedOrderPublicationType = (order:StrapiObj<DeeplyNestedOrder>) => {
      return !!order?.attributes?.condolence?.data?.id ? PublicationTypes.Condolence :
       !!order?.attributes?.obituary?.data?.id ? PublicationTypes.Obituary :
       !!order?.attributes?.remembrance?.data?.id ? PublicationTypes.Remembrance :
        null
    }

    const fetchOrder = async (id:number) => {
      const response = await OrdersAPI.getOrder(id);
      const {obituary, condolence, remembrance,...order} = response.data.attributes
      const publicationType = getDeeplyNestedOrderPublicationType(response.data)
      const corporateId = response.data?.attributes?.[publicationType]?.data?.attributes?.corporate?.data?.id || 0;
      const publicationDetails:PublicationRequest = {...response.data?.attributes?.[publicationType]?.data?.attributes, order:response?.data?.attributes?.[publicationType]?.data?.id,corporate:corporateId}
      setOrder({...order,orderId:response.data?.id, publicationDetails, product: response.data?.attributes?.product?.data?.id, })
    }

    const updateOrder = async (data:OrderRequest) => {
      const {publicationDetails:{order, ...other} ,...OrdeRest} = data
      const cleanData = {...OrdeRest, publicationDetails:other};
      if (cleanData.product === PublicationsIdsDictionary.obituary) {
        (cleanData.publicationDetails as ObituaryRequest).funeralDate = resetTimeZone((cleanData.publicationDetails as ObituaryRequest).funeralDate);
    }
    if (cleanData.product === PublicationsIdsDictionary.remembrance) {
        (cleanData.publicationDetails as RemembranceRequest).ceremonyDate = resetTimeZone((cleanData.publicationDetails as RemembranceRequest).ceremonyDate);
        (cleanData.publicationDetails as RemembranceRequest).graveAscentionDate = resetTimeZone((cleanData.publicationDetails as RemembranceRequest).graveAscentionDate);
    }
      await OrdersAPI.updateOrder(cleanData);
    }

  return {
    setOrderStatus,
    orders:filteredData,
    fetchOrder,
    order,
    showEditModal: !!order,
    hideEditModal: () => setOrder(null),
    useFormParams,
    updateOrder,
    statusFilter,
    setStatusFilter,
  }
}