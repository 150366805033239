import useSWR from "swr"
import CorporateAPI from "../api/corporate.api";
import { CorporateRequest } from "../api/request.types";
import { CorporateResponse } from "../api/response.types";
import { flatData } from "../utils/flatData.util";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function UseCorporates() {
    
    const {data, error, mutate} = useSWR('getAllCorporates', CorporateAPI.getAll);

    const flattenedData = flatData<CorporateResponse>(data?.data);
    const useFormParams = useForm<CorporateRequest>();
    const [updateCorporateId, setUpdateCorporateId] = useState<number>(null)


    const addCorporate = async (data:CorporateRequest) => {
      await CorporateAPI.create(data);
      mutate();
  }
 
  const deleteCorporate = async (id:number) => {
      await CorporateAPI.delete(id);
      mutate();
  }

  const openUpdateCorporateModal = async (id:number) => {
      setUpdateCorporateId(id);
      const corporateData = data?.data?.find(x => x.id === id);
      const corporateRequest = {...corporateData?.attributes}
      useFormParams.reset(corporateRequest)
  }

  const openAddCorporateModal = async () => {
      setUpdateCorporateId(-1);
      useFormParams.reset()
  }
 
  const updateCorporate = async (id:number, data:CorporateRequest) => {
      await CorporateAPI.update(id,data);
      mutate();
  }
    

  return {
    corporates:flattenedData,
    addCorporate,
    deleteCorporate,
    updateCorporate,
    useFormParams,
    openUpdateCorporateModal,
    updateCorporateId,
    openAddCorporateModal,
    closeCorporateModal: () => {setUpdateCorporateId(null);useFormParams.reset({})}
  }
}
