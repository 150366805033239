import {Box} from '@mui/material'
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth.hook';

interface PageLayoutProps {
    children:JSX.Element
}

export default function PageLayout(props:PageLayoutProps) {
    const {login,logout,user,loading} = useAuth();

    const fullSizesMsg = (msg:string) => <Box sx={{display:'flex',justifyContent:'center',fontSize:'30px',padding:'50px'}}>{msg}</Box>
    
  return (
    <Box padding={0}>
      {loading ? fullSizesMsg('loading') :
      <>
    <Box sx={{display:'flex',backgroundColor:'whitesmoke',padding:'30px',gap:2}}>
        <Link to='' onClick={user ? logout : login}>
            {user ? 'התנתקות' : 'התחברות'}
        </Link>
        <Link to='/'>הזמנות</Link>
        <Link to='/memorials'>תגובות</Link>
        <Link to='/suppliers'>ספקים</Link>
        <Link to='/corporates'>חברות</Link>
        <Link to='/coupons'>קופונים</Link>
    </Box>
    {user ? props.children : fullSizesMsg('התחבר כדי להמשיך')}
      </>
  }
    </Box>
  )
}