
export type PublisherType = 'private' | 'commercial'
export type GenderType = 'male' | 'female'
export type PublicationTemplateColorBase = 'light' | 'dark'
export type SendToN12Option = 'yes' | 'no'

export enum appRegions {
  all = "ALL",
  jerusalem = "JERUSALEM",
  north = "NORTH",
  haifa = "HAIFA",
  center = "CENTER",
  south = "SOUTH",
  tel_aviv = "TEL_AVIV",
  judea_and_samaria = "JUDEA_AND_SAMARIA",
}

export enum PublicationTypes {
  Condolence = "condolence",
  Obituary = "obituary",
  Remembrance = "remembrance",
}

export enum PaymentProviders {
  morning = 'morning'
}

export enum PublicationsIdsDictionary {
  condolence = 1,
  obituary = 2,
  remembrance = 3,
}

export type OrderStageName =
  | "TYPE"
  | "DETAILS"
  | "DESIGN"
  | "PREVIEW"
  | "PAYMENT"
  | "MODAL"
  | "THANKS";

export enum OrderStages {
  TYPE,
  DETAILS,
  DESIGN,
  PREVIEW,
  PAYMENT,
  MODAL,
  THANKS,
}

export const OrderStagesNameMapper: OrderStageName[] = [
  "TYPE",
  "DETAILS",
  "DESIGN",
  "PAYMENT",
  "MODAL",
  "THANKS",
];

export enum OrderStatus {
  pending = "pending",
  error = "error",
  complete = "complete",
  approved = "approved",
  rejected = "rejected",
}

export enum MemorialStatus {
  pending = "pending",
  approved = "approved",
  rejected = "rejected",
  error = "error",
}

export interface StrapiObj<Obj> {
  id: number;
  attributes: Obj;
}

export type FlattenedStrapiObj<Obj> = ({id:number} & Obj)
  
export interface ProductDetails {
    id:string;
    name:string;
    basePrice: number,
    prices: { sendToN12 : { [key in SendToN12Option]: number}}
}
export interface PublicationImagesInterface {
  N12Url: string;
  OutputUrl: string;
  PrintUrl: string;
  N12MobileUrl: string;
}

export type PublicationImagesSelection = {[key in keyof PublicationImagesInterface]: boolean};
export const publicaionImagesConst = [
  "N12Url",
  "OutputUrl",
  "PrintUrl",
  "N12MobileUrl",
] as const;
export type PublicationImagesType = typeof publicaionImagesConst[number];

export type CouponTypes = 'percentage' | 'amount';

export enum CouponStatus {
  active = 'active',
  disabled = 'disabled',
}