import AuthAPI from "../api/auth.api"
import Cookies from 'universal-cookie';
import useSWR from 'swr';
import UserAPI from "../api/user.api";
import { useEffect, useState } from "react";


const cookies = new Cookies();
export default function useAuth() {
        const [userId, setUserId] = useState(-1);

    const {data,error,mutate} = useSWR(userId === -1 ? null : `${userId}`, UserAPI.getUser,{onError:() => {
        logout();
    }})

    const login = async () => {
        let username,password;
        username = prompt("username:", "");
        password = prompt("password:", "");

        if (!username || !password) {
            alert('insert username and password');
            return
         }

         try {
            const response = await AuthAPI.login(username,password)
            cookies.set('AdminAdolamJWT',response?.data?.jwt)
            cookies.set('userId',response?.data?.user.id)
            setUserId(response?.data?.user?.id)
            mutate()
            } catch(err) {
                alert('wrong credentials')
            }
    }

    useEffect(() => {
        const userId = cookies.get('userId');
        setUserId(userId ?? -1);
    }, [mutate, userId])
    

    const logout = async () => {
        cookies.remove('AdminAdolamJWT')
        cookies.remove('userId')
        setUserId(-1);
    }

  return {
        login,
        logout,
        user:data?.data,
        loading: !data?.data && !error && userId !== -1,    
    }

}