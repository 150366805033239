import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import { SupplierCategoryRequest } from '../api/request.types';
import SupplierCategoryAPI from '../api/supplierCategory.api';

export default function UseSupplierCategories() {

    const {data, error, mutate} = useSWR('getAllSupplierCategories', SupplierCategoryAPI.getAll);

    const categoriesUseFormParams = useForm<SupplierCategoryRequest>();
    const [updateCategoryId, setUpdateCategoryId] = useState<number>(null)


    const flattenedData = data?.data?.map(category => {
        return {
            ...category.attributes,
            id: category?.id,
        }
    })

    const addCategory = async (data:SupplierCategoryRequest) => {
        await SupplierCategoryAPI.create(data);
        mutate();
    }
   
    const deleteCategory = async (id:number) => {
        await SupplierCategoryAPI.delete(id);
        mutate();
    }

    const openUpdateCategoriesModal = async (id:number) => {
        setUpdateCategoryId(id);
        const fullCategory = (await SupplierCategoryAPI.getById(id))?.data?.attributes;
        categoriesUseFormParams.reset(fullCategory)
    }

    const openAddCategoryModal = () => {
        setUpdateCategoryId(-1);
        categoriesUseFormParams.reset({})
    }
   
    const updateCategory = async (id:number, data:SupplierCategoryRequest) => {
        await SupplierCategoryAPI.update(id,data);
        mutate();
    }
    

  return {
    categories:flattenedData,
    rawCategories:data?.data,
    addCategory,
    deleteCategory,
    updateCategory,
    categoriesUseFormParams,
    openUpdateCategoriesModal,
    updateCategoryId,
    openAddCategoryModal,
    closeCategoryModal: () => {setUpdateCategoryId(null);categoriesUseFormParams.reset({})}
  }
}
