import { Delete, Edit } from '@mui/icons-material'
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { SupplierCategoryRequest, SupplierRequest } from '../api/request.types'
import EditSupplier from '../components/editSupplier.component'
import EditSupplierCategory from '../components/editSupplierCategory.component'
import UseSupplierCategories from '../hooks/useSupplierCategories.hook'
import UseSuppliers from '../hooks/useSuppliers.hook'

export default function Suppliers() {

  const {addCategory,categories,deleteCategory,updateCategory, categoriesUseFormParams,closeCategoryModal, openUpdateCategoriesModal,updateCategoryId, rawCategories,openAddCategoryModal} = UseSupplierCategories();
  const {addSupplier,suppliers,closeSupplierModal,deleteSupplier,openUpdateSupplierModal,suppliersUseFormParams,updateSupplier,updateSupplierId,openAddSupplierModal} = UseSuppliers()

  const catergoriesColumns: GridColDef[] = [
    {field: 'display_status',headerName: 'פעולות',width: 100, renderCell:({row:{id,name}}) => {
      return (
        <Box>
          <IconButton onClick={() => openUpdateCategoriesModal(id)}>
              <Edit />
          </IconButton>
          <IconButton onClick={async () => await deleteCategory(id)}>
              <Delete />
          </IconButton>
        </Box>
      )
    }},
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'שם קטגוריה', width: 200 },
    { field: 'description', headerName: 'תיאור', width: 400 },
  ];

  const suppliersColumns: GridColDef[] = [
    {field: 'display_status',headerName: 'פעולות',width: 100, renderCell:({row:{id}}) => {
      return (
        <Box>
          <IconButton onClick={() => openUpdateSupplierModal(id)}>
              <Edit />
          </IconButton>
          <IconButton onClick={async () => await deleteSupplier(id)}>
              <Delete />
          </IconButton>
        </Box>
      )
    }},
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'שם ספק', width: 200 },
    { field: 'priority', headerName: 'עדיפות', width: 200 },
  ];



  return (
    <Box sx={{height:'30vh'}}>
      <Button onClick={openAddCategoryModal}>הוסף קטגוריה</Button>
         <DataGrid
      rows={categories ?? []}
      columns={catergoriesColumns}
      pageSize={10}
      rowsPerPageOptions={[5]}
      />

    <Box sx={{height:'30vh'}}>
    <Button onClick={openAddSupplierModal}>הוסף ספק</Button>
<DataGrid
      rows={suppliers ?? []}
      columns={suppliersColumns}
      pageSize={30}
      rowsPerPageOptions={[5]}
      />
      </Box>





<Dialog open={!!updateCategoryId} onClose={closeCategoryModal}>
        <DialogTitle>
          {updateCategoryId === -1 ?
          'הוספת קטגוריה'          
          :
        'עריכת קטגוריה'
          }

        </DialogTitle>
        <DialogContent>
          <EditSupplierCategory useFormParams={categoriesUseFormParams} onSubmit={async (data:SupplierCategoryRequest) => updateCategoryId === -1 ? await addCategory(data) : await updateCategory(updateCategoryId,data)} onClose={closeCategoryModal} />
        </DialogContent>
      </Dialog>


<Dialog open={!!updateSupplierId} onClose={closeSupplierModal}>
        <DialogTitle>
        {updateSupplierId === -1 ?
          'הוספת ספק'          
          :
        'עריכת ספק'
          }          
        </DialogTitle>
        <DialogContent>
          <EditSupplier useFormParams={suppliersUseFormParams} categories={rawCategories} onSubmit={async (data:SupplierRequest) => updateSupplierId === -1 ? await addSupplier(data) : await updateSupplier(updateSupplierId,data)} onClose={closeSupplierModal} />
        </DialogContent>
      </Dialog>
    </Box>
    )
}