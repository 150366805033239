import { Edit, Delete } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import UseCoupons from '../hooks/useCoupons.hook'
import EditCoupon from '../components/editCoupon.component';
import { CouponRequest } from '../api/request.types';

export default function Coupons() {
const {addCoupon,closeCouponModal,couponUseFormParams,deleteCoupon,openAddCouponModal,openUpdateCouponModal,updateCoupon, coupons, updateCouponId} = UseCoupons()

const couponsColumns: GridColDef[] = [
    {field: 'display_status',headerName: 'פעולות',width: 100, renderCell:({row:{id}}) => {
        return (
            <Box>
                <IconButton onClick={() => openUpdateCouponModal(id)}>
                    <Edit />
                </IconButton>
                <IconButton onClick={async () => await deleteCoupon(id)}>
                    <Delete />
                </IconButton>
            </Box>
        )
    }},
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'שם קופון', width: 200 },
    { field: 'code', headerName: 'קוד קופון', width: 400 },
    { field: 'value', headerName: 'מחיר הנחה', width: 400 },
    { field: 'type', headerName: 'סוג הנחה', width: 400, valueGetter: ({row:{type}}) => type === 'percentage' ? 'אחוזים' : 'ש"ח' },

];
    return (
    <Box sx={{height:'30vh'}}>  
        <Box>
            <IconButton onClick={openAddCouponModal}>הוסף קופון</IconButton>
        </Box>
        <DataGrid
            rows={coupons ?? []}
            columns={couponsColumns}
            pageSize={10}
            rowsPerPageOptions={[5]}
        />
       
    <Dialog open={!!updateCouponId} onClose={closeCouponModal}>
        <DialogTitle>
            {updateCouponId === -1 ?
            'הוספת קופון'
            :
            'עריכת קופון'
            }
        </DialogTitle>
        <DialogContent>
            <EditCoupon
            useFormParams={couponUseFormParams}
            onSubmit={async (data:CouponRequest) => updateCouponId === -1 ? await addCoupon(data) : await updateCoupon(updateCouponId,data)}
            onClose={closeCouponModal}
            updateCouponId={updateCouponId}
            />
            </DialogContent>
    </Dialog>
    </Box>
    )

}
