import BucketeerAPI from "../api/bucketeer.api";


export const getSignedUrl = async (fileType:string) => {
    return (await BucketeerAPI.getSignedUrl(fileType)).data;
};

export const uploadFileLocallyWithBucketeer = async (fileList:FileList) => {
    const file = fileList[0];
    const signedUrlRes = await getSignedUrl(file?.type);
    await BucketeerAPI.upload(signedUrlRes.signedUrl, file);
    return signedUrlRes.publicUrl;
};