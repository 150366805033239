import React from 'react'
import { Edit, Delete } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import UseCorporates from '../hooks/useCorporates.hook';
import { CorporateRequest } from '../api/request.types';
import EditCorporate from '../components/editCorporate.component';

export default function Corporates() {

    const {corporates,useFormParams,addCorporate,closeCorporateModal,deleteCorporate,openAddCorporateModal,openUpdateCorporateModal,updateCorporate,updateCorporateId} = UseCorporates();


  const corporatesColumns: GridColDef[] = [
    {field: '',headerName: 'פעולות',width: 100, renderCell:({row:{id,name}}) => {
      return (
        <Box>
          <IconButton onClick={() => openUpdateCorporateModal(id)}>
              <Edit />
          </IconButton>
          <IconButton onClick={async () => await deleteCorporate(id)}>
              <Delete />
          </IconButton>
        </Box>
      )
    }},
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'corporateName', headerName: 'שם חברה', width: 200 },
    { field: 'description', headerName: 'תיאור', width: 400 },
  ];

  return (
    <Box sx={{height:'80vh'}}>
    <Button onClick={openAddCorporateModal}>הוסף חברה</Button>
    <DataGrid
            rows={corporates ?? []}
            columns={corporatesColumns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            />

<Dialog open={!!updateCorporateId} onClose={closeCorporateModal}>
        <DialogTitle>
          {updateCorporateId === -1 ?
          'הוספת קטגוריה'          
          :
        'עריכת קטגוריה'
          }

        </DialogTitle>
        <DialogContent>
          <EditCorporate useFormParams={useFormParams} onSubmit={async (data:CorporateRequest) => updateCorporateId === -1 ? await addCorporate(data) : await updateCorporate(updateCorporateId,data)} onClose={closeCorporateModal} />
        </DialogContent>
      </Dialog>
    </Box>

    
  )
}