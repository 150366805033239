import { request } from "../utils/api.util";
import { StrapiObj } from "./models.types";
import { CorporateRequest } from "./request.types";
import { CorporateResponse } from "./response.types";


export default class CorporateAPI {

    static async getAll () {
        return await request<StrapiObj<CorporateResponse>[]>({
            uri:'corporates',
            method:"GET",
        })
    }

    static async create (corporate:CorporateRequest) {
        return await request<StrapiObj<CorporateResponse>>({
            uri:'corporates',
            method:"POST",
            body: {
                data:corporate
            }
        })
    }

    static async delete (id:number) {
        return await request<StrapiObj<CorporateResponse>>({
            uri:`corporates/${id}`,
            method:"DELETE",
        })
    }

    static async update (id:number, corporate:CorporateRequest) {
        return await request<StrapiObj<CorporateResponse>>({
            uri:`corporates/${id}`,
            method:"PUT",
            body :{
                data:corporate
            }
        })
    }
}