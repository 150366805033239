import { request } from "../utils/api.util";
import { StrapiObj } from "./models.types";
import { SupplierCategoryRequest } from "./request.types";
import { SupplierCategoryResponse } from "./response.types";


export default class SupplierCategoryAPI {

    static async getAll () {
        return await request<StrapiObj<SupplierCategoryResponse>[]>({
            uri:'supplier-categories',
            method:"GET",
        })
    }

    static async getById (id:number) {
        return await request<StrapiObj<SupplierCategoryResponse>>({
            uri:`supplier-categories/${id}`,
            method:"GET",
        })
    }


    static async getSupplierCategories (supplierId:number) {
        return await request<StrapiObj<SupplierCategoryResponse>[]>({
            uri:`supplier-categories?filters[suppliers][id][$eq]=${supplierId}`,
            method:"GET",
        })
    }


    static async create (data:SupplierCategoryRequest) {
        return await request<StrapiObj<SupplierCategoryResponse>>({
            uri:'supplier-categories',
            method:"POST",
            body: {
                data
            }
        })
    }

    static async delete (id:number) {
        return await request<StrapiObj<SupplierCategoryResponse>>({
            uri:`supplier-categories/${id}`,
            method:"DELETE",
        })
    }

    static async update (id:number, data:SupplierCategoryRequest) {
        return await request<StrapiObj<SupplierCategoryResponse>>({
            uri:`supplier-categories/${id}`,
            method:"PUT",
            body :{
                data
            }
        })
    }

}