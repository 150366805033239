import { Box, Button, Grid } from '@mui/material'
import { UseFormReturn } from 'react-hook-form';
import { CouponRequest } from '../api/request.types';
import FormInputs, { inputView } from './formInputs.component';

interface EditCouponProps {
    useFormParams:UseFormReturn<CouponRequest>
    onClose:() => void
    onSubmit:(data:CouponRequest) => Promise<void>
    updateCouponId:number
}
 
enum CouponsFieldsRoute {
    name = 'name',
    code = 'code',
    value = 'value',
    type = 'type', 
}

export default function EditCoupon(props:EditCouponProps) {
    const {onClose,onSubmit,useFormParams, updateCouponId} = props;

    const couponTypesValues = [
    {value:'percentage', display:'אחוזים'},
    {value:'amount', display:'סכום קבוע'},
]

    const couponFields:inputView[] = [
        {fieldName: CouponsFieldsRoute.name, labelText: 'שם', colspan: 12},
        {fieldName: CouponsFieldsRoute.code, labelText: 'קוד קופון', colspan: 6},
        {fieldName: CouponsFieldsRoute.value, labelText: 'מחיר הנחה', colspan: 6, hide: updateCouponId !== -1},
        {fieldName: CouponsFieldsRoute.type, labelText: 'סוג הנחה', colspan: 6, type:'radio', values: couponTypesValues, otherOpts:{multipleSelect:true}, hide: updateCouponId !== -1},
      ];
  
    return (

    <form onSubmit={useFormParams.handleSubmit(async (data) => {await onSubmit(data); onClose()})}>
        <Grid container spacing={1}>
            <FormInputs fields={couponFields} useFormParams={useFormParams} />
        </Grid>
        <Box display={'flex'}>
            <Button type='submit'>{updateCouponId === -1 ? 'הוסף קופון' : 'עדכן קופון'}</Button>
            <Button onClick={onClose}>ביטול</Button>
        </Box>
    </form>
    )
}