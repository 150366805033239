import { request } from "../utils/api.util";
import { OrderStatus, PublicationTypes, StrapiObj, PublicationImagesType } from "./models.types";
import { OrderRequest } from "./request.types";
import { DeeplyNestedOrder,  } from "./response.types";

export default class OrdersAPI {

    static async getOrder(id:number) {
        return await request<StrapiObj<DeeplyNestedOrder>>({
            uri: `orders/${id}?populate=deep,3`,
            method: 'GET',
        });
    }
    
    static async getAllOrders() {
        return await request<StrapiObj<DeeplyNestedOrder>[]>({
            uri:"orders?populate=deep,2",
            method:"GET"
        })
    }

    static async setOrderStatus(id:number,status:OrderStatus) {
        return await request<void>({
            uri:`setOrderStatus/${id}`,
            method:"PUT",
            body:{
                status
            }
        })
    }       
    
    static async updateOrder(data:OrderRequest) {
        return await request<any>({
            uri: `orders/${data.orderId}`,
            method: 'PUT',
            body: {
                data
            }
        });
    }

    static async regenerateImages(publicationId:number,publicationType:PublicationTypes,imagesTypes: PublicationImagesType[]) {
        return await request<void>({
            uri:'regenerateOrderImages',
            method:"PUT",
            body:{
                publicationId,
                publicationType,
                imagesTypes,
            }
        })
    }     
    
    static async regenerateAllImages(imagesTypes: PublicationImagesType[]) {
        return await request<void>({
            uri:'regenerateAllOrderImages',
            method:"PUT",
            body:{
                imagesTypes
            }
        })
    }   
}