import React from 'react';
import { Routes, Route ,BrowserRouter,} from 'react-router-dom';
import PageLayout from './components/pageLayout.component';
import Corporates from './pages/corporates';
import Memorials from './pages/memorials';
import Orders from './pages/orders';
import Suppliers from './pages/suppliers';
import Coupons from './pages/coupons';

function App() {
    return (
        <BrowserRouter>
      <PageLayout>
        <Routes>
        <Route path="/" element={<Orders />} />
        <Route path="/memorials" element={<Memorials />} />
        <Route path="/suppliers" element={<Suppliers />} />
        <Route path="/corporates" element={<Corporates />} />
        <Route path="/coupons" element={<Coupons />} />

        </Routes>
      </PageLayout>
        </BrowserRouter>
    );
}

export default App;
