import React from 'react';
import { Edit } from '@mui/icons-material';
import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { OrderStatus, PublicationsIdsDictionary, PublicationTypes } from '../api/models.types';
import EditOrder from '../components/editOrder.component';
import UseOrders, { statusNames } from '../hooks/useOrders.hook';
import UseGenerateOrderImages, { GenenerateDialogsNames } from '../hooks/useGenerateOrderImages.hook';
import FormInputs from '../components/formInputs.component';
import UseCorporates from '../hooks/useCorporates.hook';

export default function Orders() {

  const {setOrderStatus, orders, fetchOrder,hideEditModal,showEditModal,useFormParams,order,updateOrder,setStatusFilter,statusFilter} = UseOrders();
  const {closeDialog,genDialog,openDialog,regenerateOutputImages,genImageFormFields,genImagesUseFormParams} = UseGenerateOrderImages()
  const {corporates} = UseCorporates();

  const columns: GridColDef[] = [
    { field: 'edit', headerName: 'עריכה', width: 70,renderCell:({row:{id}}) => (
      <Box>
          <IconButton onClick={async () => await fetchOrder(id)}>
              <Edit />
          </IconButton>
    </Box>
    )},
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'publicationId', headerName: 'מספר מודעה', width: 100 },
    { field: 'display_publication_type', headerName: 'סוג מודעה', width: 100},
    { field: 'firstName', headerName: 'שם פרטי', width: 200 },
    { field: 'lastName', headerName: 'שם משפחה', width: 200 },
    {field: 'Approve',headerName: 'אשר הזמנה',width: 200, renderCell:({row:{id,status}}) => {
      return (
      <Box>
        {status !== OrderStatus.rejected &&<Button onClick={() => setOrderStatus(+id, OrderStatus.rejected)}>סרב הזמנה</Button>}
        {status !== OrderStatus.approved && <Button onClick={() => setOrderStatus(+id, OrderStatus.approved)}>אשר הזמנה</Button>}
      </Box>)
    }},
    { field: 'email', headerName: 'אימייל', width: 200 },
    { field: 'dead_name', headerName: 'שם הנפטר', width: 200 },
    { field: 'display_payment_time_stamp', headerName: 'שולם ב:', width: 200 },
    {field: 'display_status',headerName: 'סטטוס',width: 200, renderCell:({row:{display_status,status_color}}) => <Typography sx={{color:status_color}}>{display_status}</Typography>},
    {field: 'generateImages',headerName: 'רענון תמונות',width: 200, renderCell:({row:{publicationId,publicationType}}) => {
        return publicationId && publicationType ? <Button onClick={() => openDialog(GenenerateDialogsNames.regenSingleOrderImages,publicationId, publicationType)}>רענון תמונות</Button> : null
    }}
  ];

    return (
      <>
        <Box sx={{height:'80vh'}}>
          <Box textAlign={'left'}>
            <Button variant='contained' color={'error'} onClick={() => openDialog(GenenerateDialogsNames.regenAllOrdersImages)}>רענון כל התמונות - רגיש</Button>
            <Typography marginX={5} display={'inline'}>בחר סטטוס</Typography>
          <Select
          multiple
          value={statusFilter}
          onChange={(e) => setStatusFilter(Array.isArray(e.target.value) ? e.target.value : [(e.target.value as OrderStatus)])}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {Object.values(statusNames).map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
          </Box>


            <DataGrid
              rows={orders ?? []}
              columns={columns}
              pageSize={30}
              rowsPerPageOptions={[5]}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'payment_time_stamp', sort: 'desc' }],
                },
              }}
              />
        </Box>
        <Dialog open={showEditModal} onClose={hideEditModal}>
        <DialogTitle>עריכת הזמנה מספר {order?.orderId}</DialogTitle>
        <DialogContent>
      <EditOrder corporates={corporates} publicationType={PublicationsIdsDictionary[order?.product ?? 0] as PublicationTypes} useFormParams={useFormParams} onSubmit={updateOrder} onClose={hideEditModal} />
        </DialogContent>
      </Dialog>

      <Dialog open={!!genDialog} onClose={closeDialog}>
        <DialogTitle>
          {genDialog?.dialogName}
          {genDialog?.dialogName === GenenerateDialogsNames.regenSingleOrderImages && <Typography>הזמנה מספר {genDialog?.orderId}</Typography>}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={genImagesUseFormParams.handleSubmit((data) => regenerateOutputImages(data))}>
          <FormInputs fields={[genImageFormFields]} useFormParams={genImagesUseFormParams} />
          <Button type={'submit'}>אישור</Button>
          <Button onClick={closeDialog}>ביטול</Button>
          </form>
        </DialogContent>
      </Dialog>
        </>
    );
}