import useSWR from "swr";
import CouponsAPI from "../api/coupons.api";
import { CouponResponse } from "../api/response.types";
import { CouponRequest } from "../api/request.types";
import { useForm } from "react-hook-form";
import { useState } from "react";

export default function UseCoupons(){
    const {data, error, mutate} = useSWR('getAllCoupons', CouponsAPI.getAll);
    const [updateCouponId, setUpdateCouponId] = useState<number>(null)
    
    const couponUseFormParams = useForm<CouponRequest>();
    const flattenedData = data?.data?.map(coupon => {
        return {
            ...coupon.attributes,
            id: coupon?.id,
        }
    })

    const addCoupon = async (data:CouponRequest) => {
        await CouponsAPI.create(data);
        mutate();
    }

    const deleteCoupon = async (id:number) => {
        await CouponsAPI.delete(id);
        mutate();
    }

    const openUpdateCouponModal = async (id:number) => {
        const couponData = await CouponsAPI.getById(id);
        const couponRequest = {...couponData?.data?.attributes}
        couponUseFormParams.reset(couponRequest)

        setUpdateCouponId(id);
        couponUseFormParams.reset(couponRequest)
    }

    const openAddCouponModal = async () => {
        setUpdateCouponId(-1);
        couponUseFormParams.reset({})
    }

    const updateCoupon = async (id:number, data:CouponRequest) => {
        await CouponsAPI.update(id,data);
        mutate();
    }

    return{
        coupons:flattenedData,
        addCoupon,
        deleteCoupon,
        updateCoupon,
        couponUseFormParams,
        openUpdateCouponModal,
        openAddCouponModal,
        closeCouponModal: () => {setUpdateCouponId(null);couponUseFormParams.reset({})},
        updateCouponId,
    }
}