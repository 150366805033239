import { request } from "../utils/api.util";
import { StrapiObj } from "./models.types";
import { SupplierRequest } from "./request.types";
import { SupplierResponse } from "./response.types";


export default class SupplierAPI {

    static async getAll () {
        return await request<StrapiObj<SupplierResponse>[]>({
            uri:'suppliers?populate=deep,2',
            method:"GET",
        })
    }
   
    static async getOne (id:number) {
        return await request<StrapiObj<SupplierResponse>>({
            uri:`suppliers/${id}`,
            method:"GET",
        })
    }

    static async create (supplier:SupplierRequest) {
        return await request<StrapiObj<SupplierResponse>>({
            uri:'suppliers',
            method:"POST",
            body: {
                data:supplier
            }
        })
    }

    static async delete (id:number) {
        return await request<StrapiObj<SupplierResponse>>({
            uri:`suppliers/${id}`,
            method:"DELETE",
        })
    }

    static async update (id:number, supplier:SupplierRequest) {
        return await request<StrapiObj<SupplierResponse>>({
            uri:`suppliers/${id}`,
            method:"PUT",
            body :{
                data:supplier
            }
        })
    }
}