import { Box, Button, Dialog, DialogContent, Link, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { MemorialStatus, OrderStatus } from '../api/models.types';
import useMemorials from '../hooks/useMemorials.hook';

export default function Memorials() {
  const {memorials, setMemorialStatus} = useMemorials();
  const [content, setContent] = useState('')

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'publication_id', headerName: 'מספר מודעה', width: 100 },
    { field: 'display_publication_type', headerName: 'סוג מודעה', width: 100},
    { field: 'name', headerName: 'שם פרטי', width: 200 },
    { field: 'content', headerName: 'תוכן', width: 600,renderCell:({row:{content}}) => <Box display={'flex'}><Button onClick={() => setContent(content)}>עוד...</Button><Typography>{content}</Typography></Box> },
    {field: 'display_status',headerName: 'סטטוס',width: 100, renderCell:({row:{display_status,status_color}}) => <Typography sx={{color:status_color}}>{display_status}</Typography>},
    {field: 'Approve',headerName: 'אשר הזמנה',width: 200, renderCell:({row:{id,status}}) => {
      return (
        <Box>
        {status !== OrderStatus.rejected &&<Button onClick={() => setMemorialStatus(+id, MemorialStatus.rejected)}>סרב תגובה</Button>}
        {status !== OrderStatus.approved && <Button onClick={() => setMemorialStatus(+id, MemorialStatus.approved)}>אשר תגובה</Button>}
      </Box>)
    }},
    {field: 'blank',headerName: 'מעבר לדף מודעה',width: 200, renderCell:({row:{publication_url}}) => {
      return <Link target="_blank" href={publication_url}>מעבר לדף מודעה</Link>
    }},
  ];


  return (
    <Box sx={{height:'80vh'}}>
    <DataGrid
      rows={memorials ?? []}
      columns={columns}
      pageSize={30}
      rowsPerPageOptions={[5]}
      />
       <Dialog open={!!content} onClose={() => setContent('')}>
        <DialogContent>
          <Typography>{content}</Typography>
        </DialogContent>
      </Dialog>
</Box>  )
}
