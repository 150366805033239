import { request } from "../utils/api.util";
import { LoginResponse } from "./response.types";


export default class AuthAPI {

    static async login (username:string, password:string) {
        return await request<LoginResponse>({
            uri:'auth/local',
            method:"POST",
            body:{
                identifier:username,
                password
            }
        })


    }





}